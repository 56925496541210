import React from "react";

import { getImg } from "./Image";
import "./MarkdownViewer.css";

let marked = require("marked");

const linkPattern = /a href=/g;
const imgTagOccurence = `<img src="`;

function toMarkdown(markdownStr) {
    return markdownStr
        .replace(/\n/g, ' \r\n')
        .replace(/\u00a0/g, " ");
}

function getImgPath(src) {
    const correctPath = getImg(src);

    return typeof correctPath === "string" ? correctPath : null;
}

function correctImagePaths(htmlData) {
    if (!htmlData || htmlData.indexOf(imgTagOccurence) === -1) {
        return htmlData;
    }

    const noneImgArr = htmlData.split(imgTagOccurence);

    const output = noneImgArr.map((item) => {
        if (htmlData.indexOf(imgTagOccurence + item) === -1) {
            return item;
        }

        const imgSrc = item.split(`"`)[0];
        const remaining = item.replace(imgSrc, "");

        return [imgTagOccurence, getImgPath(imgSrc), remaining].join("");
    });

    return output.join("");
}

function convertToHtml(markdownData) {
    let htmlData = marked(markdownData).replace(linkPattern, "a target='_blank' href=");

    htmlData = correctImagePaths(htmlData);

    return htmlData;
}

const MarkdownViewer = (props) => {
    return props.data ? (
        <div
            {...props}
            dangerouslySetInnerHTML={{
                __html: convertToHtml(props.toMardown ? props.toMarkdown(props.data) : toMarkdown(props.data))
            }}
            className="markdown-viewer"
        ></div>
    ) : null;
};

export default MarkdownViewer;